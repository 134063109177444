body {
  background-color: #000; /* Set to a matching or neutral color */
  font-family: 'ArcadeFont', sans-serif;
}

/* Ensure the body takes up the full viewport */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; 
}

/* Container for your app */
.App {
  flex: 1;
  position: relative; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100%; /* Ensure it takes up full height */
  z-index: 2; /* Ensure it is above the background */
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: -2; 
  opacity: 0.71;
  pointer-events: none;
}

body::before {
  background-image: url('../../client/public/backgrounds/bg_2560.png');
}

@media (max-width: 1440px) {
  body::before {
    background-image: url('../../client/public/backgrounds/bg_1440.png');
  }
}

@media (max-width: 1024px) {
  body::before {
    background-image: url('../../client/public/backgrounds/bg_1024.png');
  }
}

@media (max-width: 768px) {
  body::before {
    background-image: url('../../client/public/backgrounds/bg_768.png');
  }
}

@media (max-width: 425px) {
  body::before {
    background-image: url('../../client/public/backgrounds/bg_425.png');
  }
}

@media (max-width: 375px) {
  body::before {
    background-image: url('../../client/public/backgrounds/bg_375.png');
  }
}

@media (max-width: 320px) {
  body::before {
    background-image: url('../../client/public/backgrounds/bg_320.png');
  }
}

@font-face {
  font-family: 'ArcadeFont';
  src: url('./fonts/Arcade-N/arcade-n.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
